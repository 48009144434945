import { z, type Schema } from 'zod';

type ObjectName = 'signUp';

const signUpFlowEnum = z.enum([
  'marlee-default',
  '2022-default',
  '2022-org-link',
  'embedded-zoom',
  'embedded-mini-assessment',
  'talentPool',
  'adhd',
  'clifton',
  'coaching',
  'disc',
  'dyslexia',
  'enneagram',
  'italyCulture',
  'thaiCulture',
  'mbti',
  'oauth',
]);
export type SignUpFlow = z.infer<typeof signUpFlowEnum>;

const signUpBaseSchema = z.object({
  signUpConnection: z.string().optional(),
  signUpFlow: signUpFlowEnum.optional(),
  signUpStep: z.string().optional(),
  signUpStepPosition: z.number().optional(),
  signUpMarketing: z.boolean().optional(),
});

const signUpClickedSchema = signUpBaseSchema.extend({
  signUpCategory: z.enum(['team', 'personal', 'talentPool']),
});

const signUpSelectedSchema = signUpBaseSchema.extend({
  signUpGoalCategory: z.string(),
  signUpGoalAchieve: z.string(),
  signUpAddAnotherGoal: z.boolean().optional(),
});

const signUpAddedSchema = signUpBaseSchema.extend({
  signUpAddMember: z.number(),
  signUpRemoveMember: z.number(),
});

const signUpExploredSchema = signUpBaseSchema.extend({
  signUpAssessment: z.boolean(),
});

const signUpSchemas = {
  signUp_started: signUpBaseSchema,
  signUp_created: signUpBaseSchema,
  signUp_next: signUpBaseSchema,
  signUp_back: signUpBaseSchema,
  signUp_clicked: signUpClickedSchema,
  signUp_selected: signUpSelectedSchema,
  signUp_added: signUpAddedSchema,
  signUp_explored: signUpExploredSchema,
  signUp_completed: signUpBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { signUpSchemas };
