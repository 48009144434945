import { z, type Schema } from 'zod';

type ObjectName = 'joinRequest';

const createSchema = z.object({
  workspaceId: z.number(),
  daysSinceSignup: z.number(),
  accessType: z.enum(['member', 'admin', 'consultant', 'support']),
});

const actionSchema = createSchema.extend({
  daysPending: z.number(),
});

const joinRequestSchemas = {
  joinRequest_created: createSchema,
  joinRequest_approved: actionSchema,
  joinRequest_denied: actionSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { joinRequestSchemas };
