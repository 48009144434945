import { z } from 'zod';

import { type GroupMember } from '.';
import { type Journal } from './journal';
import { MotivationPatternSchema, type MotivationPattern } from './motivations';
import { type Organization, type OrgSearchResult } from './organization';
import { type Questionnaire } from './questionnaire';

type DeveloperUserPermissions = {
  organization_type: 'client' | 'partner';
  organization_id: number;
  organization_name: string;
  permission: 'developer';
};

export type User = {
  id: number;
  created: string;
  uuid: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  /** Not available on req.user or AppSettings user! Only when fetched via Prisma */
  avatarUrl?: string;
  profileImageUrl: string;
  gender: 'f' | 't' | 'm' | null;
  dateOfBirth: string | null;
  cultureCode: string | null;
  countryCode: string;
  languageCode: string;
  stateCode?: string;
  occupation: string | null;
  properties?: { [property: string]: any };
  onboardingCompleted?: boolean;
  /** is Admin of F4S */
  isRootUser: boolean;
  /** Is being masqueraded by this admin */
  adminUserId?: number;
  /** if account not disabled and can access F4S*/
  isEnable: boolean;
  referralCode: string;
  intercomEmailHash: string;
  companyName: string;
  isPrivate: boolean;
  dateRegistered: string;
  /** Has completed their profile post-assessment */
  subscribed: boolean;
  timezone: string | null;
  /** Is an imported user from jobEQ entitled to one free re-assessment */
  isGrandfathered: boolean;
  // questionnaire content
  questionnaireId: number;
  isQuestionnaireCompleted: boolean;
  isQuestionnaireStarted: boolean;
  questionnaires: Questionnaire[];
  roles: string[];
  workspaces: { id: number; role: 'admin' | 'member' }[];
  // We check for this value in the passport serializer so as to not cache stray legacy logins
  isNewLoginMethod?: boolean;
  lastLoginAt?: string;

  // other content
  partners: Array<{ [key: string]: any }>;
  organizations?: Organization[];
  /** CLIENT ONLY was an experiment that not many people added. */
  businessEmail?: string;

  // Deprecated properties
  /** @deprecated use {@link User.id} instead */
  _id: number;
  /** @deprecated in favor of user.id */
  userId: number;
  /**@deprecated automatically approved on signup */
  tocAccepted: boolean;
  /** @deprecated only in types, used in v1 user routes. Team marlee should confirm */
  chatStatus: boolean;
  /** @deprecated Never seen this used in over two years */
  isIconic: boolean;
  /** @deprecated No longer performing verification */
  isAccountVerified: boolean;
  /** @deprecated All motivations are free since 2019 */
  freeMotivations: MotivationPattern[];
  /** @deprecated Not sure this is actually used anywhere or by anyone <- def deprecated*/
  permissions: DeveloperUserPermissions[];
  /** @deprecated no longer used */
  clients: Array<{ [key: string]: any }>;
  /** @deprecated this is in properties now? we should migrate */
  jobType?: string;
  /** @deprecated prefer {@link timezone} wherever possible */
  utcOffset: number;
  /** @deprecated Imported user that has claimed their F4S account. Unclaimed imports should have been deleted */
  isGrandfatheredConverted: boolean;

  /** @deprecated useless value pulled in from latest questionnaire  */
  userIdFkey: number;
  /** @deprecated use {@link Questionnaire hasPaid} on the questionnaire instead */
  hasPaid: boolean;
  /** @deprecated use {@link Questionnaire hasSponsored} on the questionnaire instead */
  hasSponsored: boolean;
  /** @deprecated use {@link Questionnaire jobEqUserId} on the questionnaire instead */
  jobEqUserId?: number | null;

  /** @deprecated ActiveCampaign has been removed */
  acContactId: number | null;

  journals?: Journal[];
};

export const UserSchema = z.object({
  id: z.number(),
  created: z.string(),
  userId: z.number(),
  uuid: z.string(),
  emailAddress: z.string().trim().email(),
  businessEmail: z.string().trim().email().optional(),
  firstName: z.string(),
  lastName: z.string(),
  avatarUrl: z.string().optional(),
  gender: z.enum(['t', 'f', 'm']).nullable(),
  dateOfBirth: z.string().nullable(),
  cultureCode: z.string().nullable(),
  countryCode: z.string(),
  languageCode: z.string(),
  stateCode: z.string().optional(),
  occupation: z.string().nullable(),
  isQuestionnaireCompleted: z.boolean(),
  isQuestionnaireStarted: z.boolean(),
  isAccountVerified: z.boolean(),
  isRootUser: z.boolean(),
  isIconic: z.boolean(),
  isEnable: z.boolean(),
  referralCode: z.string(),
  intercomEmailHash: z.string(),
  chatStatus: z.boolean(),
  companyName: z.string(),
  tocAccepted: z.boolean(),
  isPrivate: z.boolean(),
  questionnaireId: z.number(),
  dateRegistered: z.string(),
  hasPaid: z.boolean(),
  freeMotivations: z.array(MotivationPatternSchema),
  profileImageUrl: z.string(),
  /** @deprecated Not sure this is actually used anywhere or by anyone */
  permissions: z.array(z.any()),
  partners: z.array(z.any()),
  // TODO: create and use questionnaires zod schema
  questionnaires: z.array(z.any()),
  clients: z.array(z.any()),
  jobType: z.string().optional(),
  // TODO: create and use organizations zod schema
  organizations: z.array(z.any()).optional(),
  properties: z.record(z.any()).optional(),

  _id: z.number(),
  subscribed: z.boolean(),
  timezone: z.string().nullable(),
  isGrandfathered: z.boolean(),
  utcOffset: z.number(),
  isGrandfatheredConverted: z.boolean(),

  userIdFkey: z.number(),
  hasSponsored: z.boolean(),
  jobEqUserId: z.number().optional().nullable(),

  acContactId: z.number().nullable(),

  roles: z.array(z.string()),
  isNewLoginMethod: z.boolean().optional(),
  workspaces: z.array(z.object({ id: z.number(), role: z.enum(['admin', 'member']) })),
});

export type UserInQuestionnaire = Pick<
  User,
  | 'id'
  | 'userId'
  | 'firstName'
  | 'lastName'
  | 'avatarUrl'
  | 'cultureCode'
  | 'countryCode'
  | 'emailAddress'
  | 'freeMotivations'
  | 'organizations'
  | 'gender'
>;

export type UserSearchResult = Pick<
  User,
  'id' | 'userId' | 'firstName' | 'lastName' | 'profileImageUrl'
> & {
  isConnected?: boolean;
  score?: number;
  orgs?: OrgSearchResult[];
};

export type UserSearchV4Result = {
  id: number;
  fullName: string;
  avatarUrl: string;
};

export type Connection = Omit<UserSearchResult, 'isQuestionnaireStarted'> & {
  occupation: string;
  subscribed: boolean;
};

/**
 * Schema defining the options of identifying a user by
 */
export const MinimalIdentifiableUserSchema = z
  .object({
    id: z.number().optional(),
    uuid: z.string().uuid().optional(),
    emailAddress: z.string().email().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    dateOfBirth: z
      .date()
      .optional()
      .refine((val) => !val || val < new Date()),
  })
  .refine(
    (val) =>
      val.id ||
      val.uuid ||
      val.emailAddress ||
      (val.firstName && val.lastName && val.dateOfBirth),
  );
export type MinimalIdentifiableUser = z.infer<typeof MinimalIdentifiableUserSchema>;

export type ConnectionByOrgAndGroup =
  | {
      organizationId: number;
      name: string;
      avatarUrl: string;
      permission: {
        canRankMembers: boolean;
        canAdminRankMembers: boolean;
        allMembersCanInvite: boolean;
      };
      groups: ConnectionByGroup[];
      subscriptionPlan: string | null;
    }
  | {
      organizationId: null;
      name: null;
      avatarUrl: null;
      permission: {
        canRankMembers: null;
        canAdminRankMembers: boolean;
        allMembersCanInvite: null;
      };
      groups: ConnectionByGroup[];
      subscriptionPlan: null;
    };

export type ConnectionByGroup =
  | {
      groupId: number;
      name: string;
      avatarUrl: string | null;
      isShare: boolean;
      curator: ConnectionCurator;
      isDummy: boolean;
      members: ConnectionMember[];
    }
  | {
      groupId: null;
      name: null;
      avatarUrl: null;
      isShare: null;
      curator: {}; // Curator with null properties
      isDummy: null;
      members: ConnectionMember[];
    };

export type ConnectionByGroupAndOrgAsGroup = ConnectionByGroup & {
  org: Partial<ConnectionByOrgAndGroup> | null;
};

export type ConnectionCurator = {
  id: number;
  firstName: string;
  lastName: string;
};

export type ConnectionMember = {
  userId: number | null;
  emailAddress: string;
  firstName: string;
  lastName: string;
  gender: string | null;
  avatarUrl: string | null;
  questionnaireId: number | null;
  questionnaireDate: Date | null;
  isGroupAdmin: boolean;
  isGroupViewer: boolean;
  isGroupMember: boolean;
  groupStatus: string;
  isOrgAdmin: boolean;
  orgStatus: string;
  createdDate: string;
  profileImageUrl: string;
};

/** Org specific properties */
const OrgCreationUserRoleProperties = z.object({
  isAdmin: z.boolean(),
  isMember: z.boolean(),
});

/** TBC type created for adding users during org creation with multi-user-select */
const OrgCreationExistingUserSchema = OrgCreationUserRoleProperties.extend({
  // Standard user properties
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  emailAddress: z.string(),
  companyName: z.string().nullable(),
  isQuestionnaireCompleted: z.boolean(),
  isQuestionnaireStarted: z.boolean(),
  profileImageUrl: z.string(),
  questionnaireId: z.number(),

  // Legacy properties
  isGrandfatheredConverted: z.boolean(),
  subscribed: z.boolean(),

  // Same as userId
  number: z.number(),
  _id: z.number(),
  id: z.number(),

  // Situational properties
  isConnected: z.boolean(),
  orgs: z
    .object({
      canBeOrgSearched: z.boolean(),
      canBeUniversalSearched: z.boolean(),
      orgId: z.number(),
      orgLogo: z.string(),
      orgName: z.string(),
    })
    .array(),
});

const OrgCreationEmailOnlyUserSchema = OrgCreationUserRoleProperties.extend({
  emailAddress: z.string(),
});

export type OrgCreationSearchUser = z.infer<typeof OrgCreationSearchUserSchema>;
export const OrgCreationSearchUserSchema = z.union([
  OrgCreationExistingUserSchema,
  OrgCreationEmailOnlyUserSchema,
]);

export type GroupWithOrg = ConnectionByGroup & {
  org: Partial<ConnectionByOrgAndGroup> | null;
};
export type UserWithOrgs = ConnectionMember & {
  orgs: Partial<ConnectionByOrgAndGroup>[] | null;
};

export type UserOrEmail =
  | { type: 'user'; user: UserSearchResult | Omit<TeamMember, 'emailAddress'> }
  | { type: 'email'; email: string };

export type UserIdOrEmail =
  | { type: 'user'; user: { userId: number } }
  | { type: 'email'; email: string };

export type TeamMember = {
  userId: number;
  memberId?: number;
  firstName: string;
  lastName?: string;
  emailAddress: string;
  profileImageUrl?: string | null;
  questionnaireId?: number;
  orgs: Organization[];
  status: GroupMember['status'];
};

export const UserProfileSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  avatarUrl: z.string().nullable(),
});

export type UserProfile = z.infer<typeof UserProfileSchema>;

type UserWithName = {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  emailAddress?: string | null;
};

/** Formats a name with proper spacing in either full or first */
export const formatName = (user: UserWithName, format: 'full' | 'first' = 'full') => {
  if (format === 'first' && typeof user.firstName === 'string') {
    return user.firstName ?? '';
  }

  const fullName = [user.firstName, user.lastName]
    .map((name) => name?.trim())
    .filter(Boolean)
    .join(' ');

  if (!fullName) return user.emailAddress ?? '';
  return fullName;
};

/** Formats a name as initials - takes same input as formatName */
export const formatInitials = (user: UserWithName) =>
  [user.firstName, user.lastName]
    .map((name) => name?.trim()[0])
    .filter(Boolean)
    .join('')
    .toUpperCase();
