import * as Sentry from '@sentry/react';
import type { History } from 'history';

export const initSentry = ({ history }: { history: History }) =>
  Sentry.init({
    dsn: window.SENTRY_DSN_CLIENT,
    release: window.SENTRY_RELEASE?.id,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.dedupeIntegration(),
      Sentry.extraErrorDataIntegration(),
    ],
    normalizeDepth: 4,
    tracesSampleRate: 0.25,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: Object Not Found Matching Id',
      'Illegal invocation',
      'Userback widget already loading',
      // HTML5 video
      'The play() request was interrupted by',
      'play() failed because the user',
      "The fetching process for the media resource was aborted by the user agent at the user's request",
      'not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    ],
    denyUrls: [
      // Gtag polluting our issues
      /googletagmanager\.com\/gtag\/js/i,
      /\/(gtm|ga|analytics)\.js/i,
      /wistia\.com\/assets/i,
      // Facebook blocked or flakiness
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_us\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Firefox extensions
      /^resource:\/\//i,
      // Safari extensions
      /@safari-extension:/i,
    ],
  });

export * as Sentry from '@sentry/react';
